import React from "react"
import { PaperBox } from "components/UIkit";
import { Typography } from "@mui/material";


const Terms = () => {
  return (

  <>

  <Typography variant="h4" component={"h1"} textAlign={"center"}>
    利用規約
  </Typography>




<PaperBox sx={{p: 3, mt:2}} >
  <Typography variant="body" sx={{m:2}}>
    この利用規約（以下，「本規約」といいます。）は，楽単アルプス運営事務所（以下，「当事務局」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
  </Typography>
</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第1条（適用）</Typography>

<ul>
<li className="mt-2">本規約は，ユーザーと当事務局との間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
<li className="mt-2">当事務局は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</li>
<li className="mt-2">本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</li>
</ul>

</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第2条（禁止事項）</Typography>
<Typography variant="subtitle1" sx={{m:2}}>ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</Typography>

<ul>
<li className="mt-2">法令または公序良俗に違反する行為</li>
<li className="mt-2">犯罪行為に関連する行為</li>
<li className="mt-2">本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</li>
<li className="mt-2">当事務局，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
<li className="mt-2">本サービスによって得られた情報を商業的に利用する行為</li>
<li className="mt-2">当事務局のサービスの運営を妨害するおそれのある行為</li>
<li className="mt-2">不正アクセスをし，またはこれを試みる行為</li>
<li className="mt-2">他のユーザーに関する個人情報等を収集または蓄積する行為</li>
<li className="mt-2">不正な目的を持って本サービスを利用する行為</li>
<li className="mt-2">本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</li>
<li className="mt-2">他のユーザーに成りすます行為</li>
<li className="mt-2">当事務局が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</li>
<li className="mt-2">面識のない異性との出会いを目的とした行為</li>
<li className="mt-2">当事務局のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
<li className="mt-2">その他，当事務局が不適切と判断する行為</li>
</ul>
</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第3条（本サービスの提供の停止等）</Typography>
<ul>
<li className="mt-2">当事務局は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</li>
<li className="mt-2">本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
<li className="mt-2">地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</li>
<li className="mt-2">コンピュータまたは通信回線等が事故により停止した場合</li>
<li className="mt-2">その他，当事務局が本サービスの提供が困難と判断した場合</li>
<li className="mt-2">当事務局は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</li>
</ul>

</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第4条（保証の否認および免責事項）</Typography>
<ul>
<li className="mt-2">当事務局は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</li>
<li className="mt-2">ウェブサイトからリンクしている他のウェブサイトに含まれている情報、サービス等については、一切の責任を負わないものとします。</li>
</ul>

</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第5条（サービス内容の変更等）</Typography>
<ul>
<li className="mt-2">当事務局は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。</li>
</ul>

</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第6条（利用規約の変更）</Typography>
<ul>
<li className="mt-2">当事務局は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。</li>
</ul>

</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第7条（著作権）</Typography>
<ul>
<li className="mt-2">ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報に関してのみ，本サービスを利用し，投稿ないしアップロードすることができるものとします。</li>
<li className="mt-2">ユーザーが本サービスを利用して投稿ないしアップロードした文章，画像，映像等の著作権については，当該ユーザーその他既存の権利者に留保されるものとします。ただし，当社は，本サービスを利用して投稿ないしアップロードされた文章，画像，映像等について，本サービスの改良，品質の向上，または不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で利用できるものとし，ユーザーは，この利用に関して，著作者人格権を行使しないものとします。</li>
</ul>

</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第8条（個人情報の取扱い）</Typography>
<ul>
<li className="mt-2">当事務局は，本サービスの利用によって取得する個人情報については，当事務局「<a href="/privacy/">プライバシーポリシー</a>」に従い適切に取り扱うものとします。</li>
</ul>

</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第9条（権利義務の譲渡の禁止）</Typography>
<ul>
<li className="mt-2">ユーザーは，当事務局の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</li>
</ul>
</PaperBox>
<PaperBox sx={{p: 3, mt:2}} >
<Typography variant="h6" sx={{m:2}}>第10条（準拠法・裁判管轄）</Typography>
<ul>
<li className="mt-2">本規約の解釈にあたっては，日本法を準拠法とします。</li>
<li className="mt-2">本サービスに関して紛争が生じた場合には，当事務局の本店所在地を管轄する裁判所を専属的合意管轄とします。</li>
</ul>
</PaperBox>




  </>
  )
}

export default Terms;